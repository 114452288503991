#home-page {

	.banner-section {
		position: relative;
		width: 100%;

		.swiper {
			.swiper-button-prev, .swiper-button-next {
		    width: 50px;
		    height: 50px;
		    // border: 2px solid transparent;
				color: #ffffff;
		    border-radius: 50%;
		    background-color: $cprimary;
		    @include transition(150ms);
		    opacity: 1;

		    &:after {
		      font-family: "Font Awesome 5 Pro";
		      font-weight: 400;
		      font-size: 1.7rem;
		    }
		  }

			.swiper-button-disabled {
				pointer-events: all !important;
			}

		  .swiper-button-prev {
		    left: 20px;
		    padding-right: 2px;

		    &:after {
		      content: "\f053";
		    }
		  }

		  .swiper-button-next {
		    right: 20px;
		    padding-left: 3px;

		    &:after {
		      content: "\f054";
		    }
		  }

			.swiper-pagination  {
				bottom: 14px;

				.swiper-pagination-bullet {
					width: 12px;
  				height: 12px;
				}
				.swiper-pagination-bullet-active {
					background-color: $cprimary;
				}
			}

		  @media screen and (max-width: 767px) {
		    .swiper-button-prev, .swiper-button-next {
		      width: 32px;
		      height: 50px;
					margin-top: - 35px;
					border-radius: 0;
		      border-width: 1px;

		      &:after {
		        font-size: 1.3rem;
						font-weight: 700;
		      }
		    }

		    .swiper-button-prev {
		      left: 0;
		    }
		    .swiper-button-next {
		      right: 0;
		    }
		  }

			.swiper-slide {
				background-color: $soft-gray;

				.box {
					display: block;
					@include transition(250ms);

					img {
						width: 100%;
					}
				}

				a.box {
					cursor: pointer;
				}
			}
		}

		.desktop {}
		.mobile {
			display: none;
		}

		@media screen and (max-width: 991px) {
			.desktop {
				display: none;
			}
			.mobile {
				display: block;
			}
		}
	}

	.policies-section {
		position: relative;
		padding-top: 20px;
		padding-bottom: 30px;

		.col-title {
			margin-bottom: 35px;
			text-align: center;
		}

		.col-policy {
			a.box-policy {
				@include transition(250ms);
				
				&:hover {
					opacity: 0.85;
				}
			}
			.box-policy {
				position: relative;
				display: block;
				margin-bottom: 14px;
				color: #595959;
				text-decoration: none !important;
				border: 1px solid #a5a5a5;
				box-shadow: 0 0 20px rgba(0,0,0,0.15);

				.b-image {
					position: relative;
					@include flex-center-xy();
					flex: 0 0 100%;
					max-width: 100%;
					height: 136px;

					>span {
						display: inline-block;
						width: 100%;
						text-align: center;

						img {
							max-width: 90%;
							height: 110px;
						}
					}
				}

				.b-price {
					@include flex-center-xy();
					padding: 5px 10px 10px 10px;
					flex: 0 0 100%;
					max-width: 100%;
					min-height: 82px;
					color: #ffffff;
					text-align: center;
					background-color: $cprimary;

					>div {
						display: inline-block;
						width: 100%;
					}

					.price {
						margin-top: 5px;
						font-size: 1.40rem;
						font-weight: 300;
					  letter-spacing: 2px;
					}

					.txt {
						margin-top: 5px;
						font-size: 0.80rem;
					  font-weight: 400;
					  letter-spacing: 2px;
					}
				}

				.b-description {
					@include flex-center-xy();
					padding: 5px 16px;
					flex: 0 0 100%;
					max-width: 100%;
					min-height: 108px;
					color: #202020;
					font-size: 0.85rem;
					line-height: 1.35;
					text-align: center;
					background-color: #ede9e9;
				}

				.b-extra {
					position: relative;
					display: block;
					padding: 22px 16px 25px 16px;

					.title {
						margin-bottom: 14px;
						color: #1e1d1d;
						font-size: 0.90rem;
						letter-spacing: 1px;
						text-align: center;
					}

					.logo-syp {
						max-height: 71px;
						max-width: 88%;
						opacity: 0.4;
					}

					.benefits {
						margin-top: 25px;

						h6 {
							position: relative;
							padding-left: 38px;
							margin-bottom: 18px;
							margin-left: 12px;
							font-size: 0.92rem;
							font-weight: 400;
						  letter-spacing: 2px;

							&:last-child {
								margin-bottom: 0;
							}

							&:before {
								content: " ";
								position: absolute;
								top: -3px;
								left: 0;
								width: 20px;
								height: 22px;
								background-position: center;
								background-repeat: no-repeat;
								background-size: contain;
							}

							&.dental {
								&:before {
									background-image: url('../images/pages/plans/i-dental.png')
								}
							}
							&.estetica {
								&:before {
									background-image: url('../images/pages/plans/i-estetica.png')
								}
							}
							&.estetica {
								&:before {
									background-image: url('../images/pages/plans/i-estetica.png')
								}
							}
							&.visual {
								&:before {
									background-image: url('../images/pages/plans/i-visual.png')
								}
							}
							&.spa {
								&:before {
									background-image: url('../images/pages/plans/i-spa.png')
								}
							}
							&.nutricion {
								&:before {
									background-image: url('../images/pages/plans/i-nutricion.png')
								}
							}
							&.psicologia {
								&:before {
									background-image: url('../images/pages/plans/i-psicologia.png')
								}
							}
						}
					}
				}

				.b-more {
					@include flex-center-xy();
					padding: 11px 0;
					flex: 0 0 100%;
					max-width: 100%;
					color: #ffffff;
				  font-size: 0.95rem;
				  font-weight: 600;
					letter-spacing: 4px;
					text-transform: uppercase;
					outline: 1px solid $cprimary;
					background-color: $cprimary;
				}
			}

			.tos-link {
				display: inline-block;
				color: $csecondary;
				font-size: 0.90rem;
				text-decoration: underline;
			}
		}

		@media screen and (max-width: 767px) {
			.col-title {
				margin-bottom: 26px;
			}

			.col-policy {
				margin-bottom: 24px;

				&:last-child {
					margin-bottom: 0;
				}
			}
		}
	}

}
